/* eslint-disable global-require */
const config = require('config');

/**
 * Because we need `logIntervalMissingCmsContentKeys` on `app.js` we can't make this a TS file.
 */

const isBrowser = typeof window !== 'undefined';

const getServerLog = () => {
  if (isBrowser) return undefined;
  // eslint-disable-next-line no-template-curly-in-string
  const bindings = eval('require(`${SERVER_PATH}/bindings`)();');
  const log = bindings.newObject('logger')('server.modules.cms') || console;
  return log;
};

/**
 * We have to use a global variable here to preserve the same object
 * in `app.js` which uses `require` and the rest that use `import`.
 */
global.missingCmsContentKeys = global.missingCmsContentKeys ?? new Set();
const { missingCmsContentKeys } = global;

/**
 * Logs the error in Opsgenie.
 * Group all missing keys in one log every 24 hours / once per day.
 * We want to prevent spam errors. In case of connection issues with Contentful we don't want to log a large number of errors at once.
 */
const logIntervalMissingCmsContentKeys = () => {
  const log = getServerLog();
  const logTime = 24 * 60 * 60 * 1000; // 24 hours
  log.info('Starting error log collector, pushing errors every %s minutes', logTime / 60 / 1000);
  setInterval(() => {
    if (missingCmsContentKeys.size > 0) {
      log.error(Array.from(missingCmsContentKeys), 'CMS keys missing.');
    } else {
      log.debug('No missing CMS keys to report');
    }
    missingCmsContentKeys.clear();
  }, logTime);
};

const logMissingCmsContentKeys = (scope, ...keys) => {
  if (isBrowser) return undefined;
  const isCmsEnabled = config.get('contentful.enabled');
  if (!isCmsEnabled) return undefined;
  return keys.forEach((key) => missingCmsContentKeys.add(`${scope}:${key}`));
};

module.exports = {
  logMissingCmsContentKeys,
  logIntervalMissingCmsContentKeys,
};
