import type { ReactNode } from 'react';
import { INLINES, MARKS } from '@contentful/rich-text-types';
import type { Options } from '@contentful/rich-text-react-renderer';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { get } from 'lodash';
import { logMissingCmsContentKeys } from '$server/modules/cms/log';
import { logErrorOnce } from '$util/index';
import type {
  CmsContentfulContentData,
  CmsContentfulImage,
  CmsContentfulRichText,
  CmsContentfulLongText,
  CmsContentfulShortText,
  ProcessedCmsContentfulImage,
  CmsContentfulLink,
} from '../contentful.types';
import { CmsContentfulTypename } from '../contentful.types';
import type { CmsContentDictionary } from '../index';
import { extendCmsText } from '../index';

const safeGetKeyTypename = (
  obj:
    | CmsContentfulShortText
    | CmsContentfulLongText
    | CmsContentfulRichText
    | CmsContentfulLink
    | CmsContentfulImage
): string => obj?.__typename;

function getDataByKey(
  this: CmsContentDictionary,
  key: string,
  fallback?:
    | string
    | Omit<CmsContentfulLink, '__typename' | 'key'>
    | Omit<CmsContentfulRichText, '__typename' | 'key'>
    | Omit<ProcessedCmsContentfulImage, '__typename' | 'key'>
): CmsContentfulContentData {
  const dict = this;
  return (dict?.components?.[key] || fallback) as CmsContentfulContentData;
}

const fallbackImageUrl =
  'https://res.cloudinary.com/fxtr/image/upload/v1623946054/landing/icons/nut-spinner-static_buiktd.svg';

export function getImageByKey(
  this: any,
  key: string,
  fallback: Omit<ProcessedCmsContentfulImage, '__typename' | 'key'>
): ProcessedCmsContentfulImage {
  const dict = this;
  const typeName = safeGetKeyTypename(dict?.components?.[key]);
  if (typeName === CmsContentfulTypename.image) {
    const imgData = getDataByKey.call(dict, key, fallback) as CmsContentfulImage;
    const image = {
      ...imgData,
      img: (imgData.img && imgData.img[0]) || {
        original_secure_url: fallbackImageUrl,
      },
    };
    return image;
  }
  logMissingCmsContentKeys(dict.pageTemplate, key);
  return { __typename: CmsContentfulTypename.image, key, ...fallback };
}

export function getRichTextByKey(this: any, key: string): CmsContentfulRichText | void {
  const dict = this;
  const typeName = safeGetKeyTypename(dict?.components?.[key]);
  if (typeName === CmsContentfulTypename.richText) {
    return getDataByKey.call(dict, key) as CmsContentfulRichText;
  }
  logMissingCmsContentKeys(dict.pageTemplate, key);
  return undefined;
}

export function getLongTextByKey(this: any, key: string, fallback: string): string {
  const dict = this;
  const typeName = safeGetKeyTypename(dict?.components?.[key]);
  if (typeName === CmsContentfulTypename.longText) {
    return (getDataByKey.call(dict, key, fallback) as CmsContentfulLongText).value;
  }
  logMissingCmsContentKeys(dict.pageTemplate, key);
  return fallback;
}

export function getShortTextByKey(this: any, key: string, fallback: string): string {
  const dict = this;
  const typeName = safeGetKeyTypename(dict?.components?.[key]);
  if (typeName === CmsContentfulTypename.shortText) {
    return (getDataByKey.call(dict, key, fallback) as CmsContentfulShortText).value;
  }
  logMissingCmsContentKeys(dict.pageTemplate, key);
  return fallback;
}

export function getLinkByKey(
  this: any,
  key: string,
  fallback: Omit<CmsContentfulLink, '__typename' | 'key'>
): CmsContentfulLink {
  const dict = this;
  const typeName = safeGetKeyTypename(dict?.components?.[key]);
  if (typeName === CmsContentfulTypename.link) {
    return getDataByKey.call(dict, key, fallback) as CmsContentfulLink;
  }
  logMissingCmsContentKeys(dict.pageTemplate, key);
  return { __typename: CmsContentfulTypename.link, key, ...fallback };
}

const transformIntercomLink = (link: string) => {
  if (!link.startsWith('http')) return link;
  const url = new URL(link);
  if (url.hostname !== 'intercom.help') return link;

  console.warn(`Old Intercom link in use: ${link}`);
  url.hostname = 'faq.fixter.co.uk';
  switch (true) {
    case url.pathname === '/fixteruk/en':
      url.pathname = '';
      break;
    case url.pathname === '/fixteruk/fr':
      url.pathname = '/fr';
      break;
    case url.pathname.startsWith('/fixteruk'):
      url.pathname = url.pathname.slice(9);
      break;
    default:
      break;
  }

  return url.href;
};

const shouldLinkOpenInNewTab = (link: string) => {
  const url = new URL(transformIntercomLink(link), 'https://fixter.co.uk');
  return url.hostname.startsWith('faq.fixter') || url.pathname.startsWith('/faq');
};

/**
 * @param fallback
 * ### Fallback
 * - can be a string or JSX
 * - example: https://github.com/fxtr/landing-react/blob/f5174abc96d2ac838a47a409a7e9d56c9d53dbc8/layout/CheckoutLayout/components/HeroImage/index.tsx#L30-L40
 *
 * @param placeholder
 * ### Placeholder - Rich Text how to use:
 * - on Contentful create a placeholder variable like so: {var}. Select the variable and click the "code" button from the rich text editor.
 * - internally we search for "{var}" and replace it into "var". This way we can have multiple placeholders inside Contentful which can be mapped into an object in landing.
 *
 * ### Placeholder example:
 * - in code: https://github.com/fxtr/landing-react/blob/f5174abc96d2ac838a47a409a7e9d56c9d53dbc8/layout/CheckoutLayout/components/HeroImage/index.tsx#L30-L40
 * - on Contentful: https://app.contentful.com/spaces/xfajj2xljhpt/entries/2T58kzXkMlCe2CHB56PYrj?previousEntries=38zJtUzat6hu7tdgrryjx9
 */
export function renderRichTextByKey(
  this: any,
  key: string,
  fallback: JSX.Element,
  placeholder?: Record<string, string | number>
): ReactNode {
  const cmsData = getRichTextByKey.call(this, key);

  try {
    let options: Options = {
      renderNode: {
        [INLINES.HYPERLINK]: (node) => {
          const {
            data: { uri: link },
            content,
          } = node;
          const linkText = get(content, '0.value', '');
          const openInNewTab = shouldLinkOpenInNewTab(link);
          return (
            <a href={link} target={openInNewTab ? '_blank' : '_self'} rel={openInNewTab ? 'noreferrer' : ''}>
              {linkText}
            </a>
          );
        },
      },
    };

    if (placeholder) {
      options = {
        renderText: (text: string) => extendCmsText(text, placeholder),
        renderMark: {
          /**
           * @deprecated use simple text like `{var}` for placeholder.
           * @todo remove after no CMS key uses this anymore.
           *
           * This mechanism for placeholder will use the `<CODE>` tag in the rich text editor.
           *
           * !!!IMPORTANT!!!
           * If using multiple tags, the `<CODE>` tag should be created first and then the other one,
           * otherwise it will fail and use the fallback instead;
           * eg.
           * Good: `<CODE>` & `<B>`
           * Bad: `<B>` & `<CODE>`
           */
          [MARKS.CODE]: (text: ReactNode) => {
            // placeholder value will be replaced by `renderText`
            const isPlaceholderValue = Object.values(placeholder).some((val) => val === text);
            if (isPlaceholderValue) return text;
            return <code>{text}</code>;
          },
        },
      };
    }
    if (cmsData) return documentToReactComponents(cmsData.content.json, options);
  } catch (error) {
    logErrorOnce(
      new Error(`Failed to render rich text key (${key}), using fallback`, {
        cause: error instanceof Error ? error : undefined,
      }),
      { key }
    );
  }

  return fallback;
}
